.p-loading-page-loder{
  position: absolute;
  top: 50%;
}
.plr-100{
  padding: 0 100px;
}
.h-100vh {
  height: 100vh;
  background-color: #edf1f7;
}
.m-center {
  display: block;
  margin: auto;
}
.flex-center{
  display: flex;
  justify-content: center;
}
.h-inherit {
  height: inherit;
}
.a-self-center {
  align-self: center;
}
.pull-right {
  float: right;
}
.p-null {
  padding: 0 !important;
}
.p-20 {
  padding: 20px;
}
.p-relative {
  position: relative;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-10{
  margin-top: 10px;
}
.mb-40 {
  margin-bottom: 40px;
}
.j-space-between {
  justify-content: space-between;
}
.mlr-40 {
  margin: 0 40px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-10{
  margin-left: 10px;
}
.mr-10{
  margin-right: 10px;
}
.w-100 {
  width: 100%;
}
.highcharts-legend-item {
  text-transform: capitalize;
}
.text-capitalize {
  text-transform: capitalize;
}
.color-red {
  color: red;
}
.d-hand {
  cursor: pointer;
}
.color-blue{
  color: #598bff;
}
// custom scrollbar design
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.d-hand{
    cursor: pointer;
}
.text-cap{
    text-transform: capitalize;
}
.hidden{
  display: none;
}