.statistics full-calendar .fc-view-harness-active{
    background: #fff;
}
.statistics full-calendar .fc-daygrid-event {
    cursor: pointer;
}
.statistics full-calendar .fc-daygrid-event .fc-event-title{
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    overflow: hidden;
    font-weight: 700;
}
.statistics full-calendar .fc-daygrid-event.annual .fc-event-title{
    color: #ffffff;
}
.statistics full-calendar .fc-daygrid-event.sick .fc-event-title{
    color: #ffffff;
}
.statistics full-calendar .fc-daygrid-event.casual .fc-event-title{
    color: #ffffff;
}
.statistics full-calendar .fc-daygrid-event.unpaid .fc-event-title{
    color: #ffffff;
}
.statistics full-calendar .fc-daygrid-event.present{
    background: #00d68f;
    color: #fff !important;
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event.pending,
.statistics full-calendar .fc-daygrid-event.low{
    background: #e5e5e5;
    color: #000000 !important;
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event.danger{
    background: #ed5249;
    color: white !important;
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event.high{
    background: #7fbba7;
    color: #ffffff !important;
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event.ontime{
    background: #e5e5e5;
    color: #000000 !important;
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event.sick{
    background: #36f;
    color: #ffffff !important;
    padding: 5px 10px;
    border: #36f;
}
.statistics full-calendar .fc-daygrid-event.casual{
    background: #fa0;
    color: #ffffff !important;
    padding: 5px 10px;
    border: #fa0;
}
.statistics full-calendar .fc-daygrid-event.annual{
    background: #36f;
    color: #ffffff !important;
    padding: 5px 10px;
    border: #36f;
}
.statistics full-calendar .fc-daygrid-event.unpaid{
    background: #36f;
    color: #ffffff !important;
    padding: 5px 10px;
    border: #36f;
}
.statistics full-calendar .fc-daygrid-event .fc-event-title{
    white-space: normal;
    text-transform: uppercase;
}
.statistics full-calendar .fc-daygrid-event:hover{
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event:focus{
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event.checkout{
    background: #ffaa00;
    color: #fff;
    padding: 5px 10px;
}
.statistics full-calendar .fc-daygrid-event .fc-event-time,
.statistics full-calendar .fc-daygrid-event .fc-daygrid-event-dot{
    display: none !important;
}
.statistics full-calendar .fc-day-today{
    background: none !important;
}
.statistics full-calendar .fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-number{
    background: #36f;
    color: #fff;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    text-align: center;
}
.statistics full-calendar .fc-daygrid-event div{
    width: 100%;
}
.statistics full-calendar .fc-daygrid-event div{
    width: 100%;
}
.statistics full-calendar .fc-daygrid-event div .fullcalender-event-custome{
    display: flex;
    justify-content: space-evenly;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}
.statistics full-calendar .fc-today-button {
    text-transform: capitalize;
}
.statistics full-calendar .fc-button-group button{
    background: transparent !important;
    border-color: #36f !important;
    text-transform: capitalize !important;
    color: #222b45 !important;
}
.statistics full-calendar .fc-button-group button:hover,
.statistics full-calendar .fc-button-group button:focus,
.statistics full-calendar .fc-button-group button.fc-button-active{
    background: #36f !important;
    border-color: #36f !important;
    text-transform: capitalize !important;
    color: #ffffff !important;
}